import { cache, json } from '@solidjs/router';
import { isServer } from 'solid-js/web';
import { getConfigValue } from '../../../../modules/config';
import { headers } from '../../../../modules/server-context/headers';
import { data } from './_data';
import type { Document } from './_data';
import type { APIHandler } from '@solidjs/start/server';

const defaultPerPage = 12;

export const GET: APIHandler = async function listReleases({ params, request }) {
	const searchParams = new URL(request.url).searchParams;
	if (!params.docType || !(params.docType in data)) {
		return new Response('', { status: 404 });
	}

	const page = parseInt(searchParams.get('page') ?? '1', 10);
	const perPage = parseInt(searchParams.get('perPage') ?? `${defaultPerPage}`, 10);

	return json(await getDocumentsServer(params.docType, { page, perPage }), { headers });
};

type Options = {
	page?: number;
	perPage?: number;
};

type DocumentsResponse = {
	items: Array<Document>;
	pages: number;
	current: number;
	next?: number;
	previous?: number;
};

async function getDocumentsServer(docType: keyof typeof data, { page = 1, perPage = defaultPerPage }: Options) {
	if (!(docType in data)) {
		return null;
	}

	const documents = await data[docType]!();

	const list = Object.values(documents)
		.filter((d) => {
			if (!d.date) {
				return true;
			}
			return new Date(d.date) < new Date();
		})
		.sort((a, b) => {
			if (a.date && b.date) {
				return new Date(b.date).valueOf() - new Date(a.date).valueOf();
			}
			return compare(a.title, b.title);
		});
	const pageCount = Math.ceil(list.length / perPage);
	return {
		items: list.slice((page - 1) * perPage, page * perPage),
		pages: pageCount,
		current: page,
		next: page - 1 === pageCount ? undefined : page + 1,
		previous: page - 1 === 0 ? undefined : page - 1,
	} satisfies DocumentsResponse;
}

export { getDocumentsServer };

const compare = new Intl.Collator('en').compare;

export const getDocuments = cache(async (type: keyof typeof data, { page = 1, perPage = defaultPerPage }: Options) => {
	if (isServer) {
		return await getDocumentsServer('press-release', { page });
	}
	const params = new URLSearchParams({ page: page.toString(), perPage: perPage.toString() });
	const res = await fetch(`https://${getConfigValue('HOST')}/api/documents/${type}?${params.toString()}`);
	if (!res.ok) {
		throw new Error('Unable to load releases');
	}
	return res.json() as unknown as DocumentsResponse;
}, 'documents');
